@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@layer base {
  html {
    @apply text-dark-800;
  }

  body {
    @apply bg-gray-50 font-sans;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-semibold;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-150;
  }

  .btn-primary {
    @apply bg-primary-400 text-white hover:bg-primary-500 focus:ring-primary-400;
  }

  .btn-secondary {
    @apply bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-200;
  }

  .btn-success {
    @apply bg-secondary-500 text-white hover:bg-secondary-600 focus:ring-secondary-500;
  }

  .btn-danger {
    @apply bg-danger-400 text-white hover:bg-red-600 focus:ring-red-400;
  }

  .btn-warning {
    @apply bg-warning-400 text-white hover:bg-yellow-500 focus:ring-yellow-400;
  }

  .card {
    @apply bg-white rounded-lg shadow-card p-4;
  }

  .input-field {
    @apply w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary-400 focus:border-primary-400;
  }

  .badge {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
  }

  .badge-success {
    @apply bg-green-100 text-green-800;
  }

  .badge-warning {
    @apply bg-yellow-100 text-yellow-800;
  }

  .badge-danger {
    @apply bg-red-100 text-red-800;
  }

  .sidebar-item {
    @apply flex items-center py-2 px-3 rounded-lg transition-colors duration-150;
  }

  .sidebar-item-active {
    @apply bg-primary-700 text-white;
  }

  .sidebar-item-inactive {
    @apply text-gray-300 hover:bg-primary-600 hover:text-white;
  }
}
